import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import mediLogo from "../assets/images/logo.png";

export default function Header() {
  const location = useLocation();
  const [show, setShow] = useState(false);

  let isHome = false;
  let isBlog = false;
  if (location.pathname === "/") isHome = true;
  else if (location.pathname.includes("blog")) {
    isBlog = true;
  }

  function showMenu() {
    setShow(true);
    document.getElementById("navbarNav").style.cssText =
      "position:inherit; visibility:visible";
  }

  function hideMenu() {
    setShow(false);
    document.getElementById("navbarNav").style.cssText = "visibility:invisible";
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top navbar-light">
        <div className="container">
          <Link className="navbar-brand logo-image" to="/">
            <img src={mediLogo} alt="alternative" style={{ maxWidth: 200 }} />
          </Link>
          <button
            className="navbar-toggler p-0 border-0"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            onClick={show ? hideMenu : showMenu}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="collapse navbar-collapse offcanvas-collapse hideMenu"
            id="navbarNav"
          >
            <ul className="navbar-nav ml-auto">
              <Link
                to="/"
                className={`nav-item nav-link text-decoration-none ${
                  isHome ? "active" : ""
                }`}
                onClick={hideMenu}
              >
                Home
              </Link>
              <Link
                to="/blogs"
                className={`nav-item nav-link text-decoration-none ${
                  isBlog ? "active" : ""
                }`}
                onClick={hideMenu}
              >
                Blogs
              </Link>
              <Link
                to="/downloads"
                className={`nav-item nav-link text-decoration-none `}
                onClick={hideMenu}
              >
                Downloads
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
