import React, { useEffect, useState } from "react";

import { Image, Shimmer } from "react-shimmer";
import { BACKEND_DOMAIN } from "../constants/medipuzzleApi";
export default function BlogCard(props) {
  const { handleBlogClick, blog } = props;

  const imageId =
    blog?.image_url?.split("id=")[1]?.split("&")[0] ??
    blog?.image_url?.split("/d/")[1]?.split("/view")[0];

  const [width, setWidth] = useState(320);

  useEffect(() => {
    const cardEl = document.getElementsByClassName("card")[0];
    if (cardEl) setWidth(cardEl.clientWidth);
  }, []);

  // const driveLinkToImage = `https://drive.google.com/thumbnail?id=${imageId}&sz=w1000`;
  const driveLinkToImage = `https://lh3.googleusercontent.com/d/${imageId}=w1000`;

  return (
    <div
      className="col-12 col-md-6 col-xl-4 col-xxl-3 text-decoration-none mb-4"
      onClick={(e) => handleBlogClick(e, blog.id)}
      style={{ minHeight: "403px" }}
    >
      <div className="card" style={{ cursor: "pointer" }}>
        <Image
          src={blog && imageId ? driveLinkToImage : blog?.image_url}
          fallback={<Shimmer height={212} width={width} />}
          errorFallback={() => (
            <img
              src="https://via.placeholder.com/300x200?text=MediPuzzle"
              alt="error-img"
            />
          )}
          NativeImgProps={{
            style: {
              width: "100%",
              minHeight: "212px",
              maxHeight: "212px",
              objectFit: "cover",
            },
            loading: "lazy",
            alt: "blog",
            className: "card-img-top",
          }}
          delay={10}
        />
        <div className="card-body">
          <small className="published-date text-sm font-weight-bolder">
            {blog.blogDate}
          </small>
          <h5 className="card-title">
            {blog?.title
              ? blog?.title.length > 50
                ? `${blog?.title.substring(0, 50)}...`
                : blog?.title.substring(0, 50)
              : ""}
          </h5>
          <p className="card-text">
            {blog.short_description
              ? blog.short_description.length > 180
                ? `${blog.short_description.substring(0, 180)}...`
                : blog.short_description.substring(0, 180)
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
}
