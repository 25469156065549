import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { DiscussionEmbed } from "disqus-react";
import { Image, Shimmer } from "react-shimmer";

import "../Scss/BlogPageView.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../constants/medipuzzleApi";
import { BACKEND_DOMAIN } from "../constants/medipuzzleApi";

export default function BlogPageView() {
  const [blog, setBlog] = useState({});
  const [newBlogs, setNewBlogs] = useState([]);

  const navigate = useNavigate();
  const { blog_id } = useParams();

  useEffect(() => {
    let requestPromises = [];
    requestPromises.push(axios.get(`${BACKEND_URL}/public/blog/${blog_id}/`));
    requestPromises.push(axios.get(`${BACKEND_URL}/public/blog/all/`));

    Promise.all(requestPromises)
      .then((responseList) => {
        if (responseList[0].status === 200) {
          setBlog(responseList[0].data?.data);
        }
        if (responseList[1].status === 200) {
          if (responseList[1]?.data) {
            if (responseList[1].data.data) {
              const blogs = responseList[1]?.data?.data;
              let downloadSection;
              if (blogs) {
                downloadSection = blogs.filter(
                  (each) =>
                    !each.title.toUpperCase().includes("DOWNLOAD") &&
                    each.is_published
                );
                setNewBlogs(downloadSection);
              }
            }
          }
        }
      })

      .catch((err) => console.log("error wile getting request", err));
  }, [blog_id]);

  const handleBlogClick = (blogId) => {
    navigate(`/blogs/${blogId}#disqus_thread`);
  };

  const imageId =
    blog?.image_url?.split("id=")[1]?.split("&")[0] ??
    blog?.image_url?.split("/d/")[1]?.split("/view")[0];
  const driveLinkToImage = `https://lh3.googleusercontent.com/d/${imageId}=w1000`;
  const renderNewBlogs = () => {
    return newBlogs.map((eachBlog, index) => {
      const imageId =
        eachBlog?.image_url?.split("id=")[1]?.split("&")[0] ??
        eachBlog?.image_url?.split("/d/")[1]?.split("/view")[0];
      const driveLinkToImage = `https://lh3.googleusercontent.com/d/${imageId}=w1000`;
      return (
        blog?.id !== eachBlog?.id && (
          <div
            key={eachBlog?.id}
            className="col-12 text-decoration-none rounded rounded-lg my-4"
            onClick={() => handleBlogClick(eachBlog?.id)}
          >
            <div
              className="card "
              style={{ height: "430px", cursor: "pointer" }}
            >
              <Image
                src={
                  eachBlog && imageId ? driveLinkToImage : eachBlog?.image_url
                }
                fallback={<Shimmer width={300} height={200} />}
                errorFallback={() => (
                  <img
                    src="https://via.placeholder.com/300x200?text=MediPuzzle"
                    alt="error-img"
                  />
                )}
                NativeImgProps={{
                  className: "card-img-top rounded rounded-lg",
                  alt: "blog",
                  style: {
                    height: "200px",
                  },
                }}
              />
              <div className="card-body p-2">
                <label className="published-date text-sm ">
                  {eachBlog?.created_at}
                </label>
                <h5 className="card-title">
                  {eachBlog?.title
                    ? eachBlog?.title.length > 50
                      ? `${eachBlog?.title.substring(0, 50)}...`
                      : eachBlog?.title.substring(0, 50)
                    : ""}
                </h5>
                <p className="card-text short-blog-description">
                  {blog.short_description
                    ? blog.short_description.length > 110
                      ? `${blog.short_description.substring(0, 110)}...`
                      : blog.short_description.substring(0, 110)
                    : ""}
                </p>
              </div>
            </div>
          </div>
        )
      );
    });
  };
  return (
    <div
      className="blog-container"
      style={{ marginTop: "125px", marginBottom: "125px" }}
    >
      <div className="container">
        <div className="row">
          <div className=" col-xl-8 col-lg-8  border">
            <h1 className="blog-title h1">{blog?.title}</h1>

            <small className="published-date opacity-25  d-block font-weight-bold ">
              Published by {blog.author ? blog.author : "MediPuzzle"}
            </small>
            <Image
              src={blog && imageId ? driveLinkToImage : blog?.image_url}
              fallback={<Shimmer width={760} height={400} />}
              errorFallback={() => (
                <img
                  src="https://via.placeholder.com/720x400?text=MediPuzzle"
                  alt="error-img"
                />
              )}
              NativeImgProps={{
                className:
                  "blog-image my-4 img-fluid w-100 h-100 object-fit-cover rounded rounded-lg",
              }}
            />

            <p className="short-blog-description">{blog?.short_description}</p>

            <p className="blog-description my-2">
              {parse(blog?.description ?? "")}
            </p>
            <div>
              {blog?.tags?.length &&
                blog.tags.map((tag) => {
                  return (
                    <button type="button" className="tags-button">
                      {tag}
                    </button>
                  );
                })}
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 ">
            <h5>Latest Blogs</h5>
            {renderNewBlogs()}
          </div>
        </div>

        <DiscussionEmbed
          className="my-4"
          shortname="medipuzzle"
          config={{
            url: window.location.href,
            identifier: { blog_id },
            title: { blog_id },
            language: "en_US",
          }}
        />
      </div>
    </div>
  );
}
